import { default as index_32copy_322X4oQiYxpyOMeta } from "/vercel/path0/pages/index copy 2.vue?macro=true";
import { default as index_32copy3ttS67DonzMeta } from "/vercel/path0/pages/index copy.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: index_32copy_322X4oQiYxpyOMeta?.name ?? "index copy 2",
    path: index_32copy_322X4oQiYxpyOMeta?.path ?? "/index%20copy%202",
    meta: index_32copy_322X4oQiYxpyOMeta || {},
    alias: index_32copy_322X4oQiYxpyOMeta?.alias || [],
    redirect: index_32copy_322X4oQiYxpyOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index copy 2.vue").then(m => m.default || m)
  },
  {
    name: index_32copy3ttS67DonzMeta?.name ?? "index copy",
    path: index_32copy3ttS67DonzMeta?.path ?? "/index%20copy",
    meta: index_32copy3ttS67DonzMeta || {},
    alias: index_32copy3ttS67DonzMeta?.alias || [],
    redirect: index_32copy3ttS67DonzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index copy.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]